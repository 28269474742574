import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Stack, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export default function RHFAutocomplete({ name, label, helperText, options, soloSelected = false, ...other }) {
    const { control } = useFormContext();
    const { translate } = useLocales();
    function isObjectEmpty(obj) {
        if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).length === 0;
        }
        return true;
    }
    return (_jsx(Controller, { name: name, control: control, defaultValue: [], render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
            }, children: [_jsx(Autocomplete, { sx: { width: '100%' }, ...field, onChange: (_, data) => {
                        const myArray = data;
                        if (soloSelected && myArray && myArray?.length > 0) {
                            field.onChange([myArray?.pop()]);
                        }
                        else {
                            field.onChange(myArray);
                        }
                    }, options: options, value: isObjectEmpty(field.value) ? [] : field.value, filterSelectedOptions: true, renderInput: (params) => (_jsx(TextField, { label: `${translate(label)} `, name: name, error: !!error, value: field.value, inputRef: field.ref, helperText: error && `${translate(error?.message)} `, ...params })), ...other }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2', marginTop: error ? '-24px' : '0px' } }) })] })) }));
}
export function RHFAutocompleteOne({ name, label, helperText, codeInOrderOfName, options, ...other }) {
    const { control } = useFormContext();
    const [textFieldValue, setTextFieldValue] = useState('');
    const { translate } = useLocales();
    return (_jsx(Controller, { name: name, control: control, defaultValue: [], render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
            }, children: [_jsx(Autocomplete, { sx: { width: '100%' }, ...field, onChange: (_, data) => {
                        field.onChange(data?.id);
                    }, options: textFieldValue !== ''
                        ? options.filter((value) => codeInOrderOfName
                            ? `${translate(value?.code)}`
                                ?.toLowerCase()
                                .includes(`${translate(textFieldValue)}`?.toLowerCase())
                            : `${translate(value?.name)}`
                                ?.toLowerCase()
                                .includes(`${translate(textFieldValue)}`?.toLowerCase()))
                        : options, filterOptions: (optionFiltered, state) => optionFiltered, value: field.value, renderInput: (params) => (_jsx(TextField, { label: `${translate(label)}`, name: name, error: !!error, value: textFieldValue, onChange: (e) => setTextFieldValue(e.target.value), helperText: error ? `${translate(error?.message)}` : `${translate(helperText)}`, ...params })), ...other }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2', marginTop: error ? '-38px' : '-5px' } }) })] })) }));
}
